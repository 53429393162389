import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';
import PatternDark from '../assets/images/patterns/escheresque_ste.png';

// import { device } from '../components/particles/MediaQueries';
import SignInForm from '../components/particles/SignIn';
// import { PasswordForgetLink } from '../components/particles/PasswordForget';
import ApplicationContext from '../components/particles/context/ApplicationContext';

import Layout from '../components/templates/Default';

const Wrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 0;
  width: 100%;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    width: 100%;

    text-align: left;
  }

  /* IE Fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;

    > * {
      max-width: 720px;
    }
  }
`;

const SignInPage = () => {
  const { state } = useContext(ApplicationContext);

  return (
    <Wrapper mode={state.theme} className="grid grid--short">
      <h1 className="h4">Welcome back!</h1>
	  <p> If you do not have a username, you can use <b>enquiries@environmentcentre.com</b> with password <b>'tECmaps2020'</b>.
	</p>
	  
      <SignInForm />
      {/* <PasswordForgetLink /> */}
    </Wrapper>
  );
};

export default () => (
  <Layout page="signin" align="center">
    <Helmet title={`${CONFIG.SITE_TITLE} | Sign In`} defer={false} />
    <SignInPage />
  </Layout>
);
