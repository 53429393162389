import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Formik } from 'formik';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../constants/routes';

const SignInFormBase = ({ firebase }) => {
  const [error, setError] = useState({});

  const loginFirebase = values => {
    const { email, password } = values;

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        navigate(ROUTES.HOME);
      })
      .catch(error => {
        setError({ error });
      });
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={values => {}}
      onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
        loginFirebase(values);
        setSubmitting(false);
      }}
    >
      {({
        errors,
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">What's your email address?</label>
            <input
              autoFocus
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              required
              value={values.email}
            />
            <label htmlFor="password">What's your password?</label>
            <input
              id="password"
              type="password"
              name="password"
              onChange={handleChange}
              required
              value={values.password}
            />
            {errors.password && (
              <label className="error" htmlFor="password">
                Sorry! {errors.password}
              </label>
            )}
            {error && error.password && (
              <label className="error" htmlFor="password">
                Sorry! {error.message}
              </label>
            )}
            <button
              type="submit"
              value="Create account"
              disabled={isSubmitting}
            >
              Log Me In
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

const SignInForm = withFirebase(SignInFormBase);

export default SignInForm;
